.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.event-headings {
  font-weight: 700;
  font-size: 30px;
  display: flex;
  align-items: center;
}
.hr-line {
  width: 100vw;
  max-width: 100vw;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.userSelect {
  border: solid #00a99d 1px;
  border-radius: 6px;
  margin-top: 1rem;
}
.label-alignlf {
  right: 30%;
  position: relative;
  color: #51515b;
  margin-top: 4px;
}

@media (max-width: 576px) {
  .event-headings {
    font-size: 24px;
  }
}
@media (max-width: 360px) {
  .event-headings {
    font-size: 18px;
  }
}
.time-picker {
  border: solid #00a99d 1px;
  border-radius: 6px;
  margin: 0;
  width: 92%;
  padding: 8px 50px 9px 64px;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-justify {
  text-align: justify;
}
.container-padding {
  padding: 3rem;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
*body {
  margin: 0;
  padding: 0;
}
.d-inline {
  display: inline;
}
.d-flex {
  display: flex;
}
.d-none {
  display: none;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-start {
  justify-content: flex-start;
}
.align-item-middle {
  align-items: center;
}
.pointer-cursor {
  cursor: pointer;
}
.mr {
  margin-right: 10px;
}
.m-zero {
  margin: 0;
}
.font-theme-color {
  color: #00a99d;
}
.text-capitilize {
  text-transform: capitalize;
}
.m-auto {
  margin: auto;
}
.font-bold {
  font-weight: 700;
}
.setting-font {
  font-size: 18px;
  font-weight: 400;
}
.setting-hr {
  border: none;
  height: 2px;
  background: #e9e9e9;
  width: 92%;
}
.setting-padding {
  padding: 10px 10px 10px 20px;
}
.border-input-color {
  border-color: #00a99d;
}
.theme-font-color {
  color: #5fc9c1;
}
.three-dot {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
