.background-style {
    position: relative;
    height:100vh;

}
.background-style:before {
    content: '';
    width: 100%;
    height: 100%;
    background: #000000d6;
    position: absolute;
    top:0;
    left:0;
    
}