
.rider-table  {
  border-collapse: collapse;
  border-spacing: 0;
  width: 50%;
  border: 1px solid #ddd;
}
.rider-table-leaderboard {
    border-collapse: collapse;
  border-spacing: 0;
    width: 80% !important;
    border: 1px solid #ddd;
}
.tableRow {
    padding: 8px !important;
}
.rider-table-leaderboard th, td {
    text-align: center;
    padding: 16px;
    width:20px;
    /* border: 1px solid black; */
  }
  
  .rider-table-leaderboard th:nth-child(even){
      background-color: #0ad1c4;
  }
  .rider-table-leaderboard th {
      background-color:#00A99D; 
  }
  .rider-table-leaderboard th:last-child {
      background-color: black !important;
      color: white;
  }
  .rider-table-leaderboard td:nth-child(even){
      background-color: #E9E9E9;
  }
  .rider-table-leaderboard td:nth-child(3) {
      background-color: #E8F3F3;
  
  }
.rider-table th, td {
  text-align: center;
  padding: 16px;
  width:20px;
  /* border: 1px solid black; */
}

.rider-table th:nth-child(even){
    background-color: #0ad1c4;
}
.rider-table th {
    background-color:#00A99D; 
}
.rider-table th:last-child {
    background-color: #00A99D !important;
    color: black;
}
.rider-table td:nth-child(even){
    background-color: #E9E9E9;
}
.rider-table td:nth-child(3) {
    background-color: #E8F3F3;

}