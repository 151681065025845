div.ten:before {
    content: "";
    position: absolute;
    border: 10px dashed #5FC9C1;
    top: -8px;
    bottom: -8px;
    left: -8px;
    right: -8px;
  }
  .ten {
    overflow: hidden;
    position: relative;
  
  
    text-align:center;
    padding:40px;
    margin-bottom:20px;
  }